/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/prop-types */

const ContentWindow = () => {

	return (
		<div style={{width: '100%'}} >
		</div>
	);
};

export default ContentWindow;